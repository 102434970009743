.login-bg-temp-2 .page-container
{
  background-color: rgba(37, 37, 37, .7);
}

.login-system-form
{
  border-color: transparent;
  margin: 80px auto 40px auto!important;
}

.login-sub-text
{
  .login-sub-para
  {
    font-size: 14px;
  }
}

.logo
{
  margin-bottom: 30px;
  margin-top: 20px;

  .login-logo-image
  {
    max-width: 100%;
    margin-bottom: 20px;
    margin-right: auto;
    margin-left: auto;
    display: block;
  }
}

.loginInputs
{
  font-size: 14px;
  background-color: transparent;
  border-right: none;
  border-left: none;
  border-top: none;
  border-radius: 0;
  margin-bottom: 18px;

  &:focus
  {
    border-color: #2c5c92;
  }
}

.loginTabs
{
  li
  {
    a
    {
      h6
      {
        text-transform: uppercase;
        font-weight: 800;
        letter-spacing: 1px;
      }
    }
  }
}

.loginCheckBox
{
  text-transform: uppercase;
  padding-top: 2px;
}

.registerCheckBox
{
  font-size: 11px !important;
  text-transform: uppercase;
  padding-top: 2px;
}

.forgotPass
{
  text-transform: uppercase;
}

.loginBut
{
  text-transform: uppercase;
}

.footer
{
  .login-footer
  {
    color: #CE2030;
  }
}

.footerLogin
{
  font-size: 14px;
}


//Forgot Password Modal
.forgotpassInput
{
  font-size: 14px;
  background-color: transparent;
  border-right: none;
  border-left: none;
  border-top: none;
  border-radius: 0;
  margin-bottom: 12px;
}

.resetPassButt
{
  font-size: 13px;
}

//Error Page
.error-text
{
  font-size: 90px;
}

.error-not-found
{
  font-size: 30px;
}

.error-form
{
  border-right: 2px solid transparent!important;
}

//Packages
.package-name
{
  font-size: 22px;
  letter-spacing: 0.5px;
}

.pricing-table-price
{
  letter-spacing: 0.5px;
}

.package-group
{
  li
  {
    font-size: 13px;

    strong
    {
      font-size: 14px;
    }
  }
}

.purchase-button
{
  font-size: 13px;
}

.back-but
{
  i
  {
    font-size: 22px;
  }
}

//iPhone 6 Plus - iPhone 6
@media (max-width: 414px) and (min-width: 375px){


}

//iPhone 5c,5s,5
@media (max-width: 370px) and (min-width: 320px){

  .login-sub-text .login-sub-para
  {
    font-size: 13px;
  }

}

//iPad Air, iPad Air 2, iPad, iPad Pro

//Portrait
@media (max-width: 768px) and (min-height: 1024px){


}

//Landscape
@media (max-width: 1032px) and (min-height: 768px){


}

#outdated {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;

  background: #D62828;
  color: #fff;

  text-align: center;

  h1 {
    font-family: "open_sanssemibold", "sans-serif";
    text-transform: uppercase;
  }

  a {
    color: #fff;
    text-decoration: underline;
  }

}